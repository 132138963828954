import styled from 'styled-components';
import { Box, IconButton, Popover } from '@material-ui/core';
import { Link } from 'gatsby-plugin-intl';

import theme from '../../../styles/themes';

export const HeaderContainer = styled(Box)`
  padding: 15px;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 2;

  ${theme.breakpoints.up('md')} {
    padding: 15px 0;
    position: relative;
  }
`;

export const MenuIconButton = styled(IconButton)`
  color: #ffffff;
`;

export const ContentContainer = styled.div`
  margin: 0 auto;

  ${theme.breakpoints.up('md')} {
    max-width: 940px;
  }

  ${theme.breakpoints.up('lg')} {
    max-width: 1200px;
  }
`;

export const DropDownList = styled(Popover)`
  .MuiPopover-paper {
    background-color: #9002ff;
    border-radius: 8px;
    min-width: 190px;

    .MuiListItem-root {
      padding: 15px 30px;
    }
  }
`;

export const LanguageButton = styled.button`
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

export const MainLink = styled(Link)`
  .MuiTypography-root {
    color: #ffffff;
  }

  &.active .MuiTypography-root {
    color: #24caff;
    font-weight: 700;
  }
`;
