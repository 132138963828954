import SEO from './SEO';

export interface ISEOProps {
  description?: string;
  lang?: string;
  meta?: any;
  title: string;
}

export default SEO;
