import { Button, TextField } from '@material-ui/core';
import styled from 'styled-components';

import theme from '../../../styles/themes';

export const ContentContainer = styled.div`
  padding: 2.5rem 1.25rem;
  margin: 0 auto;

  ${theme.breakpoints.up('sm')} {
    max-width: 550px;
    padding: 2.5rem 0;
  }

  ${theme.breakpoints.up('md')} {
    max-width: 940px;
  }

  ${theme.breakpoints.up('lg')} {
    max-width: 1200px;
  }
`;

export const NewsletterInput = styled(TextField)`
  flex-grow: 1;
  .MuiOutlinedInput-root {
    background-color: #ffffff;
    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border: none;
    }
  }
  .MuiOutlinedInput-input {
    color: #909090;
  }
`;

export const SubmitButton = styled(Button)`
  margin-left: 10px;
`;
