import styled from 'styled-components';
import theme from '../../styles/themes';

export const Layout = styled.div`
  width: 100vw;
  height: 100vh;
  padding-top: 88px;
  display: flex;
  flex-direction: column;

  ${theme.breakpoints.up('md')} {
    padding-top: 0;
  }
`;
