import React, { ReactNode, useState } from 'react';

import { Layout } from './styled';
import MobileMenu from './MobileMenu';
import Header from './Header';
import Footer from './Footer';

import { SiteContainer } from '../../styles/styled';

export type AppLayoutType = {
  children: ReactNode;
};

function AppLayout({ children }: AppLayoutType) {
  const [isOpenMenu, setIsOpenMenu] = useState(false);

  const onHandleClickMenu = () => {
    setIsOpenMenu(!isOpenMenu);
  };

  return (
    <Layout>
      <MobileMenu
        onClose={() => setIsOpenMenu(false)}
        isOpenMenu={isOpenMenu}
      />
      <Header onHandleClickMenu={() => onHandleClickMenu()} />
      <SiteContainer>{children}</SiteContainer>
      <Footer />
    </Layout>
  );
}

export default AppLayout;
