import React, { useState } from 'react';
import {
  Grid,
  Box,
  Typography,
  Hidden,
  Snackbar,
  TextField,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { FormattedHTMLMessage, Link, useIntl } from 'gatsby-plugin-intl';
import { Controller, useForm } from 'react-hook-form';
import validator from 'validator';

import { ContentContainer, NewsletterInput, SubmitButton } from './styled';
import TwitterIcon from '../../../assets/icons/icon-twitter.inline.svg';
import YoutubeIcon from '../../../assets/icons/icon-youtube.inline.svg';
import FacebookIcon from '../../../assets/icons/icon-facebook.inline.svg';

const Header = () => {
  const intl = useIntl();
  const { handleSubmit, formState, control } = useForm();
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);
  const [isErrorOpen, setIsErrorOpen] = useState(false);

  const onSubmit = (data: any) => {
    data['form-name'] = 'newsletter';
    const formData = new FormData();
    Object.keys(data).forEach((k) => {
      formData.append(k, data[k]);
    });
    fetch('', {
      method: 'POST',
      // headers: {
      //   Accept: 'application/x-www-form-urlencoded',
      //   'Content-Type': 'application/x-www-form-urlencoded',
      // },
      body: formData,
    })
      .then(() => {
        setIsSuccessOpen(true);
      })
      .catch((error) => {
        setIsErrorOpen(true);
        console.log(error);
      });
  };

  return (
    <Box component="footer" bgcolor="#0B0B0B">
      <Snackbar
        open={isSuccessOpen}
        autoHideDuration={3000}
        onClose={() => setIsSuccessOpen(false)}
      >
        <MuiAlert elevation={6} variant="filled" severity="success">
          Success
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={isErrorOpen}
        autoHideDuration={3000}
        onClose={() => setIsErrorOpen(false)}
      >
        <MuiAlert elevation={6} variant="filled" severity="error">
          Error
        </MuiAlert>
      </Snackbar>
      <ContentContainer>
        <Grid container justify="space-between" spacing={1}>
          <Hidden smDown>
            <Grid item>
              <Grid container spacing={1}>
                <Grid item style={{ minWidth: '170px' }}>
                  <Link to="/solutions">
                    <Typography
                      variant="body2"
                      style={{ color: '#EEEEEE', marginBottom: '20px' }}
                    >
                      <FormattedHTMLMessage id="headerSolutions" />
                    </Typography>
                  </Link>
                  <Link to="/inverter">
                    <Typography
                      variant="body2"
                      style={{ color: '#EEEEEE', marginBottom: '20px' }}
                    >
                      <FormattedHTMLMessage id="headerInverter" />
                    </Typography>
                  </Link>
                  <Link to="/torque-system">
                    <Typography
                      variant="body2"
                      style={{ color: '#EEEEEE', marginBottom: '20px' }}
                    >
                      <FormattedHTMLMessage id="headerTorque" />
                    </Typography>
                  </Link>
                  <Link to="/electronics">
                    <Typography variant="body2" style={{ color: '#EEEEEE' }}>
                      <FormattedHTMLMessage id="headerElectronics" />
                    </Typography>
                  </Link>
                </Grid>
                <Grid item style={{ minWidth: '170px' }}>
                  <Link to="/services">
                    <Typography
                      variant="body2"
                      style={{ color: '#EEEEEE', marginBottom: '20px' }}
                    >
                      <FormattedHTMLMessage id="headerServices" />
                    </Typography>
                  </Link>
                  <Link to="/company">
                    <Typography
                      variant="body2"
                      style={{ color: '#EEEEEE', marginBottom: '20px' }}
                    >
                      <FormattedHTMLMessage id="headerCompany" />
                    </Typography>
                  </Link>
                  <Link to="/about-us">
                    <Typography
                      variant="body2"
                      style={{ color: '#EEEEEE', marginBottom: '20px' }}
                    >
                      <FormattedHTMLMessage id="headerAbout" />
                    </Typography>
                  </Link>
                  <Link to="/careers">
                    <Typography
                      variant="body2"
                      style={{ color: '#EEEEEE', marginBottom: '20px' }}
                    >
                      <FormattedHTMLMessage id="headerCareers" />
                    </Typography>
                  </Link>
                  <Link to="/contact">
                    <Typography variant="body2" style={{ color: '#EEEEEE' }}>
                      <FormattedHTMLMessage id="headerContact" />
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Hidden>
          <Grid item style={{ maxWidth: '330px' }}>
            <Typography
              variant="subtitle1"
              style={{
                marginBottom: '10px',
                letterSpacing: '6.5%',
                color: '#ffffff',
              }}
            >
              <FormattedHTMLMessage id="footerNewsletterTitle" />
            </Typography>
            <Typography
              variant="body2"
              style={{
                marginBottom: '30px',
                letterSpacing: '3%',
                color: '#ffffff',
              }}
            >
              <FormattedHTMLMessage id="footerNewsletterText" />
            </Typography>
            <form
              onSubmit={handleSubmit(onSubmit)}
              noValidate
              netlify-honeypot="bot-field"
              data-netlify="true"
              name="newsletter"
            >
              <Grid container>
                <Controller
                  name="bot-field"
                  control={control}
                  defaultValue=""
                  render={({ onChange, value }) => (
                    <TextField
                      name="bot-field"
                      style={{ display: 'none' }}
                      id="bot-field"
                      hidden
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: true,
                    validate: (value) => validator.isEmail(value),
                  }}
                  render={({ onChange, value }) => (
                    <NewsletterInput
                      id="email"
                      name="email"
                      type="email"
                      placeholder={intl.formatHTMLMessage({
                        id: 'footerNewsletterPlaceholder',
                      })}
                      variant="outlined"
                      value={value}
                      onChange={onChange}
                      error={formState.errors.email && true}
                    />
                  )}
                />

                <SubmitButton type="submit" variant="contained" color="primary">
                  <FormattedHTMLMessage id="footerNewsletterButton" />
                </SubmitButton>
              </Grid>
            </form>
          </Grid>
          <Grid item style={{ maxWidth: '250px' }}>
            <Typography
              variant="subtitle1"
              style={{
                marginBottom: '10px',
                letterSpacing: '6.5%',
                color: '#ffffff',
              }}
            >
              <FormattedHTMLMessage id="footerSocialTitle" />
            </Typography>
            <Typography
              variant="body2"
              style={{
                marginBottom: '50px',
                letterSpacing: '3%',
                color: '#ffffff',
              }}
            >
              <FormattedHTMLMessage id="footerSocialText" />
            </Typography>
            <Grid container alignItems="center">
              <Link to="/">
                <TwitterIcon />
              </Link>
              <Link to="/" style={{ margin: '0 23px' }}>
                <YoutubeIcon />
              </Link>
              <Link to="/">
                <FacebookIcon />
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Typography
          variant="body2"
          style={{ color: '#EEEEEE', marginTop: '80px' }}
        >
          © AMG Ltd W registered no. 1801446
        </Typography>
      </ContentContainer>
    </Box>
  );
};

export default Header;
