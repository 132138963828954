import React, { useRef, useState } from 'react';
import {
  Hidden,
  Grid,
  Typography,
  MenuList,
  MenuItem,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  changeLocale,
  FormattedHTMLMessage,
  useIntl,
} from 'gatsby-plugin-intl';

import Logo from '../../../assets/icons/logo.png';
import { IHeaderProps } from './index';
import {
  HeaderContainer,
  MenuIconButton,
  ContentContainer,
  DropDownList,
  MainLink,
  LanguageButton,
} from './styled';

const Header = ({ onHandleClickMenu }: IHeaderProps) => {
  const [openCompany, setOpenCompany] = useState(false);
  const anchorCompany = useRef(null);
  const intl = useIntl();

  return (
    <HeaderContainer component="header" bgcolor="#363636">
      <ContentContainer>
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <MainLink to="/">
              <img src={Logo} alt="amg logo" />
            </MainLink>
          </Grid>
          <Grid item>
            <Hidden smDown>
              <Grid container>
                <Grid
                  item
                  container
                  alignItems="center"
                  justify="flex-end"
                  style={{ marginBottom: '15px' }}
                >
                  <Grid item>
                    <MainLink to="/eu">
                      <Typography variant="body2" style={{ color: '#BFBFBF' }}>
                        <FormattedHTMLMessage id="headerEU" />
                      </Typography>
                    </MainLink>
                  </Grid>
                  <Grid item style={{ margin: '0 20px' }}>
                    <MainLink to="/inquiry">
                      <Typography variant="body2" style={{ color: '#BFBFBF' }}>
                        <FormattedHTMLMessage id="headerInquiries" />
                      </Typography>
                    </MainLink>
                  </Grid>
                  <Grid item>
                    <LanguageButton
                      onClick={() =>
                        changeLocale(intl.locale === 'en' ? 'pl' : 'en')
                      }
                    >
                      <Typography variant="body2" style={{ color: '#BFBFBF' }}>
                        {intl.locale}.
                      </Typography>
                    </LanguageButton>
                  </Grid>
                </Grid>
                <Grid item container alignItems="center" justify="flex-end">
                  <Grid item>
                    <MainLink to="/" activeClassName="active">
                      <Grid container alignItems="center">
                        <Typography variant="body1">
                          <FormattedHTMLMessage id="headerSolutions" />
                        </Typography>
                        <ExpandMoreIcon
                          style={{ marginLeft: '4px', color: '#ffffff' }}
                        />
                      </Grid>
                    </MainLink>
                  </Grid>
                  <Grid item style={{ marginLeft: '50px' }}>
                    <MainLink to="/services" activeClassName="active">
                      <Grid container alignItems="center">
                        <Typography variant="body1">
                          <FormattedHTMLMessage id="headerServices" />
                        </Typography>
                        <ExpandMoreIcon
                          style={{ marginLeft: '4px', color: '#ffffff' }}
                        />
                      </Grid>
                    </MainLink>
                  </Grid>
                  <Grid item style={{ marginLeft: '50px' }}>
                    <Grid
                      onClick={() => setOpenCompany(!openCompany)}
                      style={{ cursor: 'pointer' }}
                      ref={anchorCompany}
                      container
                      alignItems="center"
                    >
                      <Typography variant="body1" style={{ color: '#ffffff' }}>
                        <FormattedHTMLMessage id="headerCompany" />
                      </Typography>
                      <ExpandMoreIcon
                        style={{ marginLeft: '4px', color: '#ffffff' }}
                      />
                    </Grid>
                    <DropDownList
                      id="company-drop-down-menu"
                      open={openCompany}
                      anchorEl={anchorCompany.current}
                      anchorOrigin={{
                        horizontal: 'center',
                        vertical: 'bottom',
                      }}
                      transformOrigin={{
                        horizontal: 'center',
                        vertical: 'top',
                      }}
                      onClose={() => setOpenCompany(false)}
                      role="menu"
                    >
                      <MenuList>
                        <MenuItem>
                          <MainLink to="/about-us">
                            <Typography>
                              <FormattedHTMLMessage id="headerAbout" />
                            </Typography>
                          </MainLink>
                        </MenuItem>
                        <MenuItem>
                          <MainLink to="/careers">
                            <Typography>
                              <FormattedHTMLMessage id="headerCareers" />
                            </Typography>
                          </MainLink>
                        </MenuItem>
                      </MenuList>
                    </DropDownList>
                  </Grid>
                  <Grid item style={{ marginLeft: '50px' }}>
                    <MainLink to="/" activeClassName="active">
                      <Typography variant="body1">
                        <FormattedHTMLMessage id="headerMedia" />
                      </Typography>
                    </MainLink>
                  </Grid>
                  <Grid item style={{ marginLeft: '50px' }}>
                    <MainLink to="/contact" activeClassName="active">
                      <Typography variant="body1">
                        <FormattedHTMLMessage id="headerContact" />
                      </Typography>
                    </MainLink>
                  </Grid>
                </Grid>
              </Grid>
            </Hidden>
            <Hidden mdUp>
              <MenuIconButton
                aria-label="mobile menu"
                onClick={onHandleClickMenu}
              >
                <MenuIcon fontSize="large" />
              </MenuIconButton>
            </Hidden>
          </Grid>
        </Grid>
      </ContentContainer>
    </HeaderContainer>
  );
};

export default Header;
