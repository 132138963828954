import React from 'react';
import { List, ListItem, Divider, Drawer } from '@material-ui/core';
import { Link, useIntl } from 'gatsby-plugin-intl';

import { IMobileMenuProps } from './index';
import { MenuContainer, LinkText } from './styled';

const MobileMenu = ({ onClose, isOpenMenu }: IMobileMenuProps) => {
  const intl = useIntl();
  return (
    <Drawer open={isOpenMenu} onClose={onClose}>
      <MenuContainer>
        <List component="nav" aria-label="top navigation">
          <Link to="/">
            <ListItem>
              <LinkText
                primary={intl.formatHTMLMessage({ id: 'headerHome' })}
              />
            </ListItem>
          </Link>
          <Divider />
          <Link to="/services">
            <ListItem>
              <LinkText
                primary={intl.formatHTMLMessage({ id: 'headerServices' })}
              />
            </ListItem>
          </Link>
          <Divider />
          <Link to="/about-us">
            <ListItem>
              <LinkText
                primary={intl.formatHTMLMessage({ id: 'headerAbout' })}
              />
            </ListItem>
          </Link>
          <Divider />
          <Link to="/careers">
            <ListItem>
              <LinkText
                primary={intl.formatHTMLMessage({ id: 'headerCareers' })}
              />
            </ListItem>
          </Link>
          <Divider />
          <Link to="/contact">
            <ListItem>
              <LinkText
                primary={intl.formatHTMLMessage({ id: 'headerContact' })}
              />
            </ListItem>
          </Link>
          <Divider />
          <Link to="/eu">
            <ListItem>
              <LinkText
                primary={intl.formatHTMLMessage({ id: 'headerEU' })}
              />
            </ListItem>
          </Link>
          <Divider />
          <Link to="/inquiry">
            <ListItem>
              <LinkText
                primary={intl.formatHTMLMessage({ id: 'headerInquiries' })}
              />
            </ListItem>
          </Link>
          <Divider />
        </List>

        <List component="nav" aria-label="secondary menu"></List>
      </MenuContainer>
    </Drawer>
  );
};

export default MobileMenu;
