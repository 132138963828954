import styled from 'styled-components';
import { Box, ListItemText } from '@material-ui/core';

export const MenuContainer = styled(Box)`
  height: 100%;
  width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const LinkText = styled(ListItemText)`
  color: #000000;
`;
